import classNames from "classnames";
import { Button } from "components/button";
import { Cloudinary, CloudinaryVideo } from "components/cloudinary";
import { Container } from "components/container";
import InteractiveMap from "components/interactive-map";
import { Lightbox } from "components/lightbox";
import { SearchBar } from "components/search2";
import { Icon, Shape } from "components/shape";
import { TipTapComponent } from "components/wrapper/components/text";
import { entitiesConfig } from "config/entity";
import { InteractiveMapMarker, PageProps } from "constants/types";
import { useFormContext } from "contexts/form-context";
import _debounce from "lodash/debounce";
import { createClipPathPolygon } from "helpers/clipPath";
import { useLabels } from "helpers/hooks";
import { sanitize } from "helpers/text-processing";
import { isEmpty } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { colors } from "theme/theme";
import { Pc, Vi } from "types";

const ButtonColorMapping = {
  blue: "primary",
  orange: "secondary",
  grey: "tertiary",
  white: "white",
};

export const Overview = ({
  content,
  pc,
  interactiveMarkers,
  parentIndustryBackground,
  header,
}: {
  content: PageProps["content"]["page"];
  header?: PageProps["header"];
  interactiveMarkers?: InteractiveMapMarker[];
  parentIndustryBackground?: Vi;
  pc?: Pc;
}) => {
  const overviewContentRef = useRef<HTMLDivElement>();
  // get search label
  const [searchLabel] = useLabels([
    entitiesConfig[content?.search_entity_class ?? "_default"]
      ?.overview_search_label ?? entitiesConfig._default?.overview_search_label,
    "Find your Product",
  ]);
  const { setServiceFormOpen } = useFormContext();
  const { page_layout, platform_background, platform_buttons } = content;
  const { title, subtitle, excerpt, key_visual } = content;
  const buttons = platform_buttons
    ? typeof platform_buttons === "string"
      ? JSON.parse(platform_buttons)
      : platform_buttons
    : [];
  const [clipPath, setClipPath] = useState<string>("");

  const videoElement = pc?.[0].overview_video?.[0];
  const videoKeyVisual =
    videoElement?.key_visual?.[0] ?? pc?.[0].key_visual?.[0];
  const [videoLabel] = useLabels(["ui-872", "Play video"]);
  const [open, setOpen] = useState(false);
  const isServiceOverview = !!content.family_icon;

  // sometimes null is saved as a string, why?
  let excerptWithFallback =
    excerpt !== "null" && excerpt !== null ? excerpt : pc?.[0]?.excerpt;
  const interactiveBackground = pc?.[0]?.video_background?.[0];
  const keyvisualWithFallback = isEmpty(key_visual)
    ? pc?.[0]?.key_visual?.[0]
    : key_visual;
  const titleWithFallback = title || pc?.[0]?.title;
  const subtitleWithFallback = subtitle || pc?.[0]?.subtitle;

  try {
    excerptWithFallback = JSON.parse(excerptWithFallback);
  } catch (e) {
    // this is probably an html element
  }

  useEffect(() => {
    const updateClipPath = () => {
      setClipPath(
        createClipPathPolygon(overviewContentRef.current, 8, "start"),
      );
    };

    if (overviewContentRef.current) {
      updateClipPath();

      window.addEventListener("resize", _debounce(updateClipPath, 50));

      return () => {
        window.removeEventListener("resize", _debounce(updateClipPath, 50));
      };
    }
  }, [overviewContentRef, content, pc]);

  return page_layout && page_layout !== "default" ? (
    <div
      className={classNames(
        "header-overview",
        `header-overview--${platform_background ?? "white"}`,
        page_layout !== "header" && page_layout,
        !keyvisualWithFallback && " no-keyvisual",
      )}
      key={keyvisualWithFallback?.id}
    >
      {(keyvisualWithFallback || interactiveBackground) && (
        <div
          className={`header-overview__keyvisual ${interactiveBackground ? "interactive-background" : ""
            }`}
        >
          {interactiveBackground ? (
            <InteractiveMap
              pc={pc}
              background={interactiveBackground}
              markers={interactiveMarkers}
              breadcrumb={header?.breadcrumb}
              parentIndustryBackground={parentIndustryBackground}
            />
          ) : (
            <Cloudinary
              media={keyvisualWithFallback}
              ar={page_layout === "small-header" ? "ar165" : null}
              arBackground={page_layout === "small-header" ? null : "ar169"}
              background="grey-triangle"
              priority={true}
            />
          )}
        </div>
      )}
      <div
        ref={overviewContentRef}
        style={{ clipPath: clipPath }}
        className={`header-overview__background background-${platform_background ?? "white"
          }`}
      ></div>
      <div className={`container header-overview__wrapper`}>
        <div
          className={classNames(
            "header-overview__wrapper__content",
            `header-overview__wrapper__content--${platform_background ?? "white"
            }`,
            isServiceOverview && "service-content-container",
          )}
        >
          <div
            className={classNames(
              "margin-bottom-small header-overview__wrapper__content__container container",
              isServiceOverview &&
              "grow flex-col flex-between-start service-content-container",
            )}
          >
            <div>
              {content.family_icon && (
                <Shape
                  variant={content.family_icon as Icon}
                  size={48}
                  fill={colors.white}
                  className="mb-2 d-block"
                />
              )}

              <h1
                className="h3"
                dangerouslySetInnerHTML={{ __html: titleWithFallback }}
              />
              {subtitleWithFallback && (
                <h2
                  className="h5"
                  dangerouslySetInnerHTML={{ __html: subtitleWithFallback }}
                />
              )}
              {excerptWithFallback &&
                typeof excerptWithFallback === "string" ? (
                <div
                  className="excerpt styled-list-wrapper"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(excerptWithFallback),
                  }}
                />
              ) : (
                <TipTapComponent tree={excerptWithFallback} />
              )}
              {content?.entity_search && (
                <div className="overview-search">
                  <SearchBar
                    header={header}
                    types={
                      content?.search_entity_class
                        ? [content?.search_entity_class]
                        : null
                    }
                    title={searchLabel.label}
                    overview
                  />
                </div>
              )}
              {!isEmpty(videoElement) && (
                <>
                  <Lightbox
                    heading={{
                      title: videoElement.title,
                      stylingLevel: "h4",
                    }}
                    headingType="primary"
                    background="grey-triangle"
                    size="large"
                    open={open}
                    setOpen={setOpen}
                  >
                    <Container>
                      <CloudinaryVideo {...{ media: videoElement }} />
                    </Container>
                  </Lightbox>
                  <div className="media-element" onClick={() => setOpen(true)}>
                    <div className="media-keyvisual">
                      <Cloudinary
                        media={videoKeyVisual ?? keyvisualWithFallback}
                        ar="ar11"
                        background="grey-triangle"
                      />
                    </div>
                    <div className="media-text">
                      <h3 className="h6 bold m-0">{videoElement.title}</h3>
                      <div className="media-play">
                        <Shape
                          variant="play-circle"
                          fill={
                            platform_background === "blue" ||
                              platform_background === "orange"
                              ? colors.white
                              : colors.blue
                          }
                        />
                        <span className="btn-text">{videoLabel.title}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {buttons && buttons.length > 0 && (
              <div
                className={classNames(
                  "product-actions",
                  "flex-column",
                  "mt-3",
                  isServiceOverview &&
                  "border-top-subtle border-top-subtle__blue margin-top-medium padding-top-small w-full",
                )}
              >
                <div
                  className={classNames(
                    "inline-actions",
                    "margin-bottom-small",
                  )}
                >
                  {buttons.map((button, index) => {
                    const buttonColor =
                      button.color !== ButtonColorMapping[platform_background]
                        ? button.color
                        : "white";
                    return (
                      <Button
                        // If order of buttons changes don't use index here
                        key={index}
                        outline={button.border}
                        variant={buttonColor}
                        icon={button.icon}
                        label={button.text}
                        url={button.link}
                        target={button.blank ? "_blank" : "_self"}
                        onClick={
                          button.open_service_form
                            ? () => setServiceFormOpen(true)
                            : undefined
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
