import { Button } from "components/button";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { getEdgeSettings } from "components/wrapper/components";
import DefaultComponent from "components/wrapper/components/default";
import HeadingWrapper from "components/wrapper/components/heading";
import ImagesWrapper from "components/wrapper/components/images";
import { headerLevel, WrapperComponentProps } from "constants/types";
import { useFormContext } from "contexts/form-context";
import { FC } from "react";

export const TipTapComponent = ({ tree }) => {
  return (
    <>
      {Array.isArray(tree) &&
        tree.map((element, index) => {
          const TagName =
            components[element.type || "default"] ?? components.default;

          return (
            <TagName
              {...element.attrs}
              type={element.type}
              key={element.type + "-" + index}
            >
              {element.content ? element.content : null}
            </TagName>
          );
        })}
    </>
  );
};

const ButtonWrapper = ({
  color,
  text,
  link,
  blank,
  open_service_form,
  ...props
}) => {
  const { setServiceFormOpen } = useFormContext();
  return (
    <Button
      variant={color}
      label={text}
      url={link}
      target={blank ? "_blank" : "_self"}
      onClick={open_service_form ? () => setServiceFormOpen(true) : undefined}
      {...props}
    />
  );
};

const set_components = {
  default: DefaultComponent,
  button: ButtonWrapper,
  image: ImagesWrapper,
};

const Set = ({ type, values }) => {
  const TagName =
    set_components[values.type || "default"] ?? set_components.default;
  return <TagName {...values} />;
};

const Header = (props) => {
  // TODO: what about italic/bold inside?
  return (
    <Heading
      level={`h${props.level}` as headerLevel}
      title={props?.children?.map((child) => child?.text).join("")}
    />
  );
};

const Paragraph = (props) => {
  return (
    <p {...props.attrs}>
      {props.children &&
        props.children.map((child, index) => {
          const isLink =
            child.marks && child.marks.some((mark) => mark.type === "link");

          if (isLink) {
            const mark = child.marks.find((mark) => mark.type === "link");

            return (
              <a
                className={`${
                  child.marks
                    ? child.marks.map((mark) => mark.type).join(" ")
                    : ""
                }`}
                key={index}
                {...mark.attrs}
              >
                {child.text}
              </a>
            );
          }

          switch (child.type) {
            case "text":
              return (
                <span
                  className={`${
                    child.marks
                      ? child.marks.map((mark) => mark.type).join(" ")
                      : ""
                  }`}
                  key={index}
                >
                  {child.text}
                </span>
              );
            case "hard_break":
              return <br key={index} />;
            default:
              return null;
          }
        })}
    </p>
  );
};

const Table = (props) => {
  return (
    <table className="table table-striped">
      <tbody>
        <TipTapComponent tree={props.children} />
      </tbody>
    </table>
  );
};

const TableRow = (props) => {
  return (
    <tr>
      <TipTapComponent tree={props.children} />
    </tr>
  );
};

const TableCell = (props) => {
  return (
    <td
      colSpan={props.colspan > 1 ? props.colspan : undefined}
      rowSpan={props.rowspan > 1 ? props.rowspan : undefined}
    >
      <TipTapComponent tree={props.children} />
    </td>
  );
};

const TableHeader = (props) => {
  return (
    <th
      colSpan={props.colspan > 1 ? props.colspan : undefined}
      rowSpan={props.rowspan > 1 ? props.rowspan : undefined}
    >
      <TipTapComponent tree={props.children} />
    </th>
  );
};

const Blockquote = (props) => {
  return (
    <blockquote className="text-blockquote">
      <TipTapComponent tree={props.children} />
    </blockquote>
  );
};

const BulletList = (props) => {
  return (
    <ul>
      <TipTapComponent tree={props.children} />
    </ul>
  );
};

const OrderedList = (props) => {
  return (
    <ol>
      <TipTapComponent tree={props.children} />
    </ol>
  );
};

const ListItem = (props) => {
  return (
    <li>
      <TipTapComponent tree={props.children} />
    </li>
  );
};

const components = {
  default: DefaultComponent,
  heading: Header,
  table: Table,
  table_row: TableRow,
  table_cell: TableCell,
  table_header: TableHeader,
  paragraph: Paragraph,
  blockquote: Blockquote,
  ordered_list: OrderedList,
  list_item: ListItem,
  bullet_list: BulletList,
  set: Set,
};

interface TextWrapperProps extends WrapperComponentProps {
  rich_text?: any[];
}

const TextWrapper: FC<TextWrapperProps> = (props) => {
  const { rich_text } = props;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={`component__text-${props.index}`}
      className="section component__text styled-list-wrapper"
    >
      <HeadingWrapper {...props} />
      <TipTapComponent tree={rich_text} />
    </Container>
  );
};

export default TextWrapper;
